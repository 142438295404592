import { Pipe, PipeTransform } from '@angular/core';
import { TextControlService } from '../services/text-control.service';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  constructor(private textControlService: TextControlService) {
  }

  transform(value: string, length: number = 100, font: string = null, letters: number = 10): string {
    if (this.textControlService.getPixelWidth(value, font) > length) {
      let lastIndex = letters;
      const availableLength = length - this.textControlService.getPixelWidth('...', font);
      let availableCharacters = value.slice(0, lastIndex);
      if (this.textControlService.getPixelWidth(availableCharacters, font) > availableLength) {
        while (this.textControlService.getPixelWidth(availableCharacters, font) > availableLength) {
          availableCharacters = availableCharacters.slice(0, availableCharacters.length - 1);
        }
      } else {
        while (this.textControlService.getPixelWidth(availableCharacters, font) < availableLength){
          lastIndex += 1;
          availableCharacters = value.slice(0, lastIndex);
        }
      }
      return availableCharacters + '...';
    } else {
      return value;
    }
  }
}
