import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { ExpenseStatus } from '../models/expense-status.model';

@Injectable()
export class ExpenseStatusesEntityService extends EntityCollectionServiceBase<ExpenseStatus> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ExpenseStatus', serviceElementsFactory);
  }
}
