import { createFeatureSelector, createSelector } from '@ngrx/store';
import { sharedFeatureKey, SharedState } from './shared.reducer';

const getSharedState = createFeatureSelector<SharedState>(sharedFeatureKey);

export const getLoadingSpinner = createSelector(getSharedState, (state) => state.showLoadingSpinner);

export const getErrorMessage = createSelector(getSharedState, (state) => state.errorMessage);

export const getCurrentLanguage = createSelector(getSharedState, (state) => state.currentLanguage);

export const getUnclaimedReports = createSelector(getSharedState, (state) => state.unClaimedReports);

export const getLastDocumentName = createSelector(getSharedState, (state) => state.lastDocumentName);
