import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Subject } from '../models/subject.model';

@Injectable()
export class SubjectsEntityService extends EntityCollectionServiceBase<Subject> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Subject', serviceElementsFactory);
  }
}
