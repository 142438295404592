import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule, EntityDataService } from '@ngrx/data';
import { entityConfig } from './entity-metadata';
import { reducers } from './shared/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthPageModule } from './core/auth/auth.module';
import { ExpensesEntityService } from './services/expenses-entity.service';
import { ExpensesDataService } from './services/expenses-data.service';
import { ExpensesResolver } from './services/expenses-resolver';
import { SubjectsEntityService } from './services/subjects-entity.service';
import { SubjectsResolver } from './services/subjects-resolver';
import { WorkDetailsEntityService } from './services/work-details-entity.service';
import { WorkDetailsResolver } from './services/work-details-resolver';
import { UsersEntityService } from './services/users-entity.service';
import { UsersResolver } from './services/users-resolver';
import { RolesEntityService } from './services/roles-entity.service';
import { RolesResolver } from './services/roles-resolver';
import { ExpenseStatusesEntityService } from './services/expense-statuses-entity.service';
import { ExpenseStatusesResolver } from './services/expense-statuses-resolver';
import { SharedModule } from './shared/shared.module';
import { ExpensesPerUserEntityService } from './services/expenses-per-user-entity.service';
import { ExpensesPerUserResolver } from './services/expenses-per-user-resolver';
import { ReportsEntityService } from './services/reports-entity.service';
import { ReportsResolver } from './services/reports-resolver';
import { ReportStatusesEntityService } from './services/report-statuses-entity.service';
import { ReportStatusesResolver } from './services/report-statuses-resolver';
import { CompanyCreditCardsEntityService } from './services/company-credit-cards-entity.service';
import { CompanyCreditCardsResolver } from './services/company-credit-cards-resolver';
import { RejectCommentEntityService } from './services/reject-comment-entity.service';
import { RejectCommentResolver } from './services/reject-comment-resolver';
import { RejectCommentService } from './services/reject-comment.service';
import { ExpensesPerUserDataService } from './services/expenses-per-user-data.service';
import { ReportsDataService } from './services/reports-data.service';
import { UserModule } from './core/user/user.module';
import { TextControlService } from './services/text-control.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot(entityConfig),
    AuthPageModule,
    SharedModule,
    UserModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ExpensesEntityService,
    ExpensesDataService,
    ExpensesResolver,
    ExpensesPerUserEntityService,
    ExpensesPerUserDataService,
    ExpensesPerUserResolver,
    SubjectsEntityService,
    SubjectsResolver,
    WorkDetailsEntityService,
    WorkDetailsResolver,
    UsersEntityService,
    UsersResolver,
    RolesEntityService,
    RolesResolver,
    ExpenseStatusesEntityService,
    ExpenseStatusesResolver,
    ReportsEntityService,
    ReportsDataService,
    ReportsResolver,
    ReportStatusesEntityService,
    ReportStatusesResolver,
    CompanyCreditCardsEntityService,
    CompanyCreditCardsResolver,
    RejectCommentEntityService,
    RejectCommentResolver,
    RejectCommentService,
    TextControlService,
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
  constructor(
    entityDataService: EntityDataService,
    expensesDataService:ExpensesDataService,
    expensesPerUserDataService: ExpensesPerUserDataService,
    reportsDataService: ReportsDataService,
  ){
    entityDataService.registerService('Expense', expensesDataService);
    entityDataService.registerService('ExpensePerUser', expensesPerUserDataService);
    entityDataService.registerService('Report', reportsDataService);
  }
}
