import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RolesEntityService } from './roles-entity.service';

@Injectable()
export class RolesResolver implements Resolve<boolean> {
  constructor(private rolesEntityService: RolesEntityService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.rolesEntityService.getAll().pipe(
      map(roles => !!roles)
    );
  }
}
