import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { RegisterComponent } from './core/auth/components/register/register.component';
import { LoginComponent } from './core/auth/components/login/login.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/expenses-to-report',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    // loadChildren: () => import('./core/auth/auth.module').then(m => m.AuthPageModule),
    children: [
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
    ]
  },
  {
    path: 'expenses',
    loadChildren: () => import('./pages/user/expenses/expenses.module').then(m => m.ExpensesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'expenses-to-report',
    loadChildren: () => import('./pages/user/expenses-to-report/expenses-to-report.module').then(m => m.ExpensesToReportPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/user/reports/reports.module').then(m => m.ReportsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    children: [
      {
        path: 'users',
        loadChildren: () => import('./pages/admin/users/users.module').then(m => m.UsersPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'subjects',
        loadChildren: () => import('./pages/admin/subjects/subjects.module').then(m => m.SubjectsPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'work-details',
        loadChildren: () => import('./pages/admin/work-details/work-details.module').then(m => m.WorkDetailsPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'expense-statuses',
        loadChildren: () => import('./pages/admin/expense-statuses/expense-statuses.module').then(m => m.ExpenseStatusesPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'report-statuses',
        loadChildren: () => import('./pages/admin/report-statuses/report-statuses.module').then(m => m.ReportStatusesPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'company-cards',
        loadChildren: () => import('./pages/admin/company-credit-cards/company-credit-cards.module').then(m => m.CompanyCreditCardsPageModule),
        canActivate: [AuthGuard],
      }
    ],
  },
  {
    path: 'accounting',
    children:[
      {
        path: 'all-claimed-expenses',
        loadChildren: () => import('./pages/accounting/all-claimed-expenses/all-claimed-expenses.module').then( m => m.AllClaimedExpensesPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/accounting/reports/reports.module').then( m => m.ReportsPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'reports-to-approve',
        loadChildren: () => import('./pages/accounting/reports-to-approve/reports-to-approve.module').then( m => m.ReportsToApprovePageModule),
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
