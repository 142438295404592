import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { RejectComment } from '../models/reject-comment.model';

@Injectable({
  providedIn: 'root'
})
export class RejectCommentEntityService extends EntityCollectionServiceBase<RejectComment> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('RejectComment', serviceElementsFactory);
  }
}
