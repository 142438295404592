import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { autoLogin, logout } from './core/auth/store/auth.actions';
import { MenuController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { selectIsLoggedIn, selectRole, selectUserEmail, selectUserId } from './core/auth/store/auth.selectors';
import { UserService } from './core/user/services/user.service';
import { ReportsDataService } from './services/reports-data.service';
import {setLastDocumentName, setUnclaimedReports} from './shared/store/shared.actions';
import {getLastDocumentName, getUnclaimedReports} from './shared/store/shared.selectors';
import {DocumentService} from './core/auth/services/document.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  userId$: Observable<number>;
  email$: Observable<string>;
  isLoggedIn$: Observable<boolean>;
  role$: Observable<string[]>;

  unClaimedReports$: Observable<string[]>;

  isAdminOrAccount: boolean;
  isAdmin: boolean;

  lastDocumentName$: Observable<string>;

  userPages = [
    { title: '費用リスト(全体)', url: '/expenses', icon: 'calculator' },
    { title: '費用リスト(未請求)', url: '/expenses-to-report', icon: 'calculator' },
    { title: 'レポートリスト', url: '/reports', icon: 'calculator' },
  ];

  accountPages = [
    { title: '費用リスト(管理用)', url: '/accounting/all-claimed-expenses', icon: 'calculator' },
    { title: 'レポートリスト(管理用)', url: '/accounting/reports', icon: 'calculator' },
    { title: 'レポートリスト(要承認)', url: '/accounting/reports-to-approve', icon: 'calculator' },
  ];

  adminPages = [
    { title: 'ユーザー', url: '/admin/users', icon: 'person-add' },
    { title: '勘定科目', url: '/admin//subjects', icon: 'folder' },
    { title: '作業詳細', url: '/admin//work-details', icon: 'file-tray-stacked' },
    { title: '費用ステータス', url: '/admin//expense-statuses', icon: 'footsteps' },
    { title: 'レポートステータス', url: '/admin/report-statuses', icon: 'footsteps'},
    { title: '法人カード一覧', url: '/admin/company-cards', icon: 'card'},
  ];

  constructor(
    private store: Store,
    private menu: MenuController,
    private userService: UserService,
    private reportsDataService: ReportsDataService,
    private documentService: DocumentService,
  ) {
    this.store.dispatch(autoLogin());
    this.userId$ = this.store.select(selectUserId);
    this.email$ = this.store.select(selectUserEmail);
    this.isLoggedIn$ = this.store.select(selectIsLoggedIn);
    this.role$ = this.store.select(selectRole);
    this.lastDocumentName$ = this.store.select(getLastDocumentName);

    this.unClaimedReports$ = store.select(getUnclaimedReports);
  }

  ngOnInit(): void {
    this.isLoggedIn$.subscribe(
      res => {
        if (res){
          this.isAdminOrAccount = this.checkAuthForAccountingTab();
          this.isAdmin = this.checkAuthForAdmin();

          this.userId$.pipe(take(1)).toPromise().then((id) => {
            this.reportsDataService.getUnclaimedReports(id).toPromise().then((reportTitles: {title: string}[]) => {
              const reportTitleList = reportTitles.map((reportTitle) => reportTitle.title);
              this.store.dispatch(setUnclaimedReports({reportTitleList}));
            });
          });
        }
      }
    );

    this.documentService.getLastVersionOfDoc().toPromise().then(
      lastDocumentName => {
        this.store.dispatch(setLastDocumentName({lastDocumentName}));
      }
    );
  }

  checkAuthForAccountingTab(): boolean {
    let isAdmin = false;
    let isAccount = false;

    const subscription = this.role$.subscribe(
      roles => {
        isAdmin = roles?.includes('admin');
        isAccount = roles?.includes('account');
      }
    );
    subscription.unsubscribe();

    return isAdmin || isAccount;
  }

  checkAuthForAdmin(): boolean {
    let isAdmin = false;

    const subscription = this.role$.subscribe(
      roles => {
        isAdmin = roles?.includes('admin');
      }
    );
    subscription.unsubscribe();

    return isAdmin;
  }

  logout() {
    this.store.dispatch(logout());
    this.menu.close('side-menu');
  }

  closeMenu(): void {
    console.log('close');
    this.menu.close('side-menu');
  }

  async changePassword() {
    const subscription = this.userId$.subscribe(
      id => {
        this.userService.openChangePasswordModal(id);
      }
    );
    subscription.unsubscribe();
  }

  getVersionOfDoc(fileName) {
    if (fileName) {
      const pattern = new RegExp(/\d\d\d\d\d\d\d\d/);
      const match = fileName.match(pattern);
      if (match) {
        return match[0];
      }
    }
  }
}
