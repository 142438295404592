import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Report } from '../models/report.model';

@Injectable()
export class ReportsEntityService extends EntityCollectionServiceBase<Report> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Report', serviceElementsFactory);
  }
}
