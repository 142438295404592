import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable } from 'rxjs';
import { Report } from '../models/report.model';

@Injectable()
export class ReportsDataService extends DefaultDataService<Report> {

  baseUrl = '/api/report';

  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super('Report', http, httpUrlGenerator);
  }

  getAll(): Observable<Report[]> {
    return this.http.get<Report[]>(this.baseUrl + '?order=desc');
  }

  getUnclaimedReports(userId: number) {
    return this.http.get(this.baseUrl + '/unclaimed/' + userId.toString());
  }
}
