import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { ReportsEntityService } from './reports-entity.service';

@Injectable()
export class ReportsResolver implements Resolve<boolean> {
  constructor(private reportsEntityService: ReportsEntityService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // if data is not loaded in store, then load new data
    return this.reportsEntityService.loaded$.pipe(
      tap(loaded => {
        if (!loaded) {
          this.reportsEntityService.getAll();
        }
      }),
      filter(loaded => !!loaded),
      first()
    );
  }
}
