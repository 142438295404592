import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { createAction, Store } from '@ngrx/store';
import { autoLogin, autoLogout, loginStart, loginSuccess, logout } from './auth.actions';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import { setErrorMessage } from '../../../shared/store/shared.actions';
import { EMPTY, of } from 'rxjs';
import { User } from '../../../models/user.model';
import { IonModalService } from 'src/app/services/ion-modal.service';
import { AlertController } from '@ionic/angular';
import { MessageBoxService } from 'src/app/services/message-box.service';
import { ExpensesPerUserEntityService } from '../../../services/expenses-per-user-entity.service';
import { UserService } from '../../user/services/user.service';

@Injectable()
export class AuthEffects {

  login$ = createEffect(() => this.actions$.pipe(
      ofType(loginStart),
      exhaustMap((action) => this.authService.login(action.email, action.password).pipe(
          map((user: User) => {
            if (!user.lastPasswordChangedAt) {
              this.userService.openChangePasswordModal(user.id, true);
              return action;
            }
            this.authService.setUserInLocalStorage(user);
            return loginSuccess({ user, redirect: true });
          }),
          catchError(() => {
            this.messageBoxService.presentAlert('ログインに失敗しました。再度お試しくだだい。');
            return EMPTY;
          })
        ))
    ));

  loginRedirect$ = createEffect(() => this.actions$.pipe(
      ofType(loginSuccess),
      tap((action) => {
        console.log('loginSuccess');
        this.store.dispatch(setErrorMessage({ message: '' }));
        if (action.redirect) {
          const redirectUrl = this.route.snapshot.queryParams.redirectUrl;
          this.router.navigateByUrl(redirectUrl);
        }
      }),
    ), { dispatch: false });

  logout$ = createEffect(
    () => this.actions$.pipe(
      ofType(...[logout, autoLogout]),
      tap((action) => {
        this.ionModalService.checkModalAndClose();
        localStorage.removeItem('userData');
        this.expensesPerUserEntityService.clearCache();
        this.router.navigateByUrl('/auth/login');
      })
    ),
    { dispatch: false }
  );

  autoLogin$ = createEffect(() => this.actions$.pipe(
      ofType(autoLogin),
      mergeMap((action) => {
        const user = this.authService.getUserFromLocalStorage(true);
        if (user === null) {
          return EMPTY;
        }
        return of(loginSuccess({ user, redirect: false }));
      })
    ));

  constructor(
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private store: Store,

    private ionModalService: IonModalService,
    private messageBoxService: MessageBoxService,
    private expensesPerUserEntityService: ExpensesPerUserEntityService,
  ) {
  }
}
