import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../../../shared/store';
import { autoLogout } from '../store/auth.actions';
import { User } from '../../../models/user.model';
import { AlertController, ModalController } from '@ionic/angular';
import { MessageBoxService } from 'src/app/services/message-box.service';
import { ChangePasswordComponent } from '../../user/components/change-password/change-password.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  timeoutInterval: any;

  baseUrl = '/api/auth';

  constructor(
    private http: HttpClient,
    private store: Store,

    private messageBoxService: MessageBoxService,
    private modalController: ModalController,
    ) {}

  login(email: string, password: string) {
    return this.http.post('/api/auth/signin', { email, password });
  }

  setUserInLocalStorage(user: User) {
    localStorage.setItem('userData', JSON.stringify(user));

    this.runTimeoutInterval(user);
  }

  runTimeoutInterval(user: User, autoLogin = false) {
    const now = new Date().getTime();
    const expirationDate = new Date(user.expiration).getTime();
    const timeInterval = expirationDate - now;

    this.timeoutInterval = setTimeout(async () => {
      if (!autoLogin){
        this.messageBoxService.presentAlert('再度ログインしてください。', 'Login expired');
      }
      this.store.dispatch(autoLogout());
    }, timeInterval);
  }

  getUserFromLocalStorage(autoLogin = false): User {
    const userDataString = localStorage.getItem('userData');
    if (userDataString) {
      console.log('userData');
      const userData = JSON.parse(userDataString);
      this.runTimeoutInterval(userData, autoLogin);
      return userData;
    }
    return null;
  }

  confirmPassword(dto: {id: number; password: string}) {
    return this.http.post<boolean>(`${this.baseUrl}/confirm-password`, dto);
  }
}
