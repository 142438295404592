import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { UsersEntityService } from './users-entity.service';

@Injectable()
export class UsersResolver implements Resolve<boolean> {
  constructor(private usersEntityService: UsersEntityService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // if data is not loaded in store, then load new data
    return this.usersEntityService.loaded$.pipe(
      tap(loaded => {
        if (!loaded) {
          this.usersEntityService.getAll();
        }
      }),
      filter(loaded => !!loaded),
      first()
    );
  }
}
