import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Role } from '../models/role.model';

@Injectable()
export class RolesEntityService extends EntityCollectionServiceBase<Role> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Role', serviceElementsFactory);
  }
}
