import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authFeatureKey, AuthState } from './auth.reducer';

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

export const selectCurrentUser = createSelector(
  selectAuthState,
  auth => auth.user,
);

export const selectIsLoggedIn = createSelector(
  selectCurrentUser,
  user => !!user,
);

export const selectIsLoggedOut = createSelector(
  selectIsLoggedIn,
  loggedIn => !loggedIn,
);

export const selectUserName = createSelector(
  selectCurrentUser,
  user => `${ user.firstName } ${ user.lastName }`,
);

export const selectUserEmail = createSelector(
  selectCurrentUser,
  user => user?.email,
);

export const selectUserId = createSelector(
  selectCurrentUser,
  user => user.id,
);

export const selectCompanyCreditCards = createSelector(
  selectCurrentUser,
  user => user.companyCreditCards,
);

export const selectRole = createSelector(
  selectCurrentUser,
  user => user?.roles.map(role => role.name)
    // if (user && roleNames.includes('admin')) {
    //   return 'Admin';
    // } else if (roleNames.includes('account')) {
    //   return 'Account';
    // } else if (roleNames.includes('engineer')) {
    //   return 'Engineer';
    // } else if (roleNames.includes('salse')) {
    //   return 'Sales';
    // } else {
    //   return 'None';
    // }
);
