export class TextControlService {
  sliceText(value: string, length: number, font: string = null, letters: number = 10) {
    if (this.getPixelWidth(value, font) > length){
      const lines = [];
      let firstIndex = 0;
      let lastIndex = letters;

      let line = value.slice(firstIndex, lastIndex);

      while (true) {
        if (this.getPixelWidth(line, font) > length){
          while (this.getPixelWidth(line, font) > length) {
            line = line.slice(0, line.length - 1);
          }
        } else {
          while (this.getPixelWidth(line, font) < length) {
            lastIndex += 1;
            line = value.slice(firstIndex, lastIndex);
            if (lastIndex === value.length) {
              lines.push(line);
              return lines;
            }
          }
        }
        lines.push(line);
        firstIndex += line.length;
        lastIndex = firstIndex + letters;
        if (lastIndex >= value.length) {
          line = value.slice(firstIndex, value.length);
          lines.push(line);
          return lines;
        }
        line = value.slice(firstIndex, lastIndex);
      }
    } else {
      return [value];
    }
  }

  getPixelWidth(text, font=null): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    context.font = font || getComputedStyle(document.body).font;

    return context.measureText(text).width;
  }
}
