import { createAction, props } from '@ngrx/store';

export const setLoadingSpinner = createAction(
  '[Shared] Set Loading Spinner',
  props<{status: boolean}>()
);

export const setErrorMessage = createAction(
  '[Shared] Set Error Message',
  props<{message: string}>()
);

export const setCurrentLanguage = createAction(
  '[Shared] Set Language',
  props<{CurrentLanguage: string}>()
);

export const setUnclaimedReports = createAction(
  '[Shared] Set Unclaimed Reports',
  props<{reportTitleList: string[]}>()
);

export const setLastDocumentName = createAction(
  '[Shared] Set Last Document Name',
  props<{ lastDocumentName: string }>()
);
