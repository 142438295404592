import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { ReportStatus } from '../models/report-status.model';

@Injectable()
export class ReportStatusesEntityService extends EntityCollectionServiceBase<ReportStatus> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('ReportStatus', serviceElementsFactory);
  }
}
