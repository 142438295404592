import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addNewLines'
})
export class AddNewLinesPipe implements PipeTransform {

  transform(value: string, length: number = 20): string {
    const maxLength = value.length;
    let newLinedValue = '';
    for (let i = 0; i <= maxLength; i += length) {
      const lastLine = i + maxLength > maxLength;
      const line = value.slice(i, lastLine?maxLength - 1:i + maxLength);
      if (!lastLine) {
        newLinedValue += line + '\n';
      } else {
        newLinedValue += line;
      }
    }

    return newLinedValue;
  }
}
