import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { CompanyCreditCard } from '../models/company-credit-card.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyCreditCardsEntityService extends EntityCollectionServiceBase<CompanyCreditCard> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('CompanyCreditCard', serviceElementsFactory);
  }
}
