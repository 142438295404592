import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { DocumentService} from '../../services/document.service';
import { AlertController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '../../../../shared/store';
import { Store } from '@ngrx/store';
import { loginStart } from '../../store/auth.actions';
import {Observable} from 'rxjs';
import {setLastDocumentName} from '../../../../shared/store/shared.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;

  rememberMe = false;

  latestVersionOfDoc$: Observable<string>;

  passwordRecommend = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private alertController: AlertController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private documentService: DocumentService,
  ) {

  }

  ngOnInit() {
    const emailInit = localStorage.getItem('rememberMe');
    if (emailInit) {
      this.rememberMe = true;
    }
    this.form = this.fb.group({
        email: [emailInit, Validators.required],
        password: ['', Validators.required],
        rememberMe: [this.rememberMe],
      }
    );
    this.latestVersionOfDoc$ = this.documentService.getLastVersionOfDoc();
    this.latestVersionOfDoc$.toPromise().then(
      (documentName) => {
        this.store.dispatch(setLastDocumentName({ lastDocumentName: documentName }));
      }
    );
  }

  onSubmit() {
    this.store.dispatch(loginStart(this.form.value));
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      message: 'ログインに失敗しました。再度お試しくだだい。',
      buttons: ['OK']
    });

    await alert.present();
  }

  rememberEmail() {
    if (this.rememberMe) {
      localStorage.setItem('rememberMe', this.form.controls.email.value);
    }
  }

  onRememberMeToggle() {
    this.rememberMe = !this.rememberMe;
    if (this.rememberMe) {
      localStorage.setItem('rememberMe', this.form.controls.email.value);
    } else {
      localStorage.removeItem('rememberMe');
    }
  }

  getVersionOfDoc(fileName) {
    if (fileName) {
      const pattern = new RegExp(/\d\d\d\d\d\d\d\d/);
      const match = fileName.match(pattern);
      if (match) {
        return match[0];
      }
    }
  }
  passwordRecommendation() {
    this.passwordRecommend = !this.passwordRecommend;
  }

  passwordIconName(): string {
    return this.passwordRecommend ? 'eye' : 'eye-off';
  }
  passwordClassName(): string {
    return this.passwordRecommend ? 'text' : 'password';
  }
}
