import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RejectComment } from '../models/reject-comment.model';

@Injectable({
  providedIn: 'root'
})
export class RejectCommentService {

  baseUrl = '/api/rejectcomment'

  constructor(private http: HttpClient) { }

  addMany(reportIds: number[], commentData: {userId: number, comment: any}){
    console.log({reportIds, commentData})
    return this.http.post<RejectComment[]>(this.baseUrl + '/report', {reportIds, commentData})
  }
}
