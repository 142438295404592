import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ChangePasswordDto } from 'src/app/models/change-password-dto.model';
import { User } from 'src/app/models/user.model';
import { MessageBoxService } from 'src/app/services/message-box.service';
import { UserService } from '../../../user/services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

  @Input() initPassword: boolean;
  @Input() userId: number;
  form: FormGroup;

  constructor(
    private modalController: ModalController,
    private fb: FormBuilder,
    private userService: UserService,
    private messageBoxService: MessageBoxService,
  ) {
    this.form = this.fb.group({
      currentPassword: ['', [Validators.required, this.passwordLengthValidator()]],
      newPassword: ['', [Validators.required, this.passwordLengthValidator()]],
      newPasswordConfirm: ['', [Validators.required, this.passwordLengthValidator()]],
    });
  }

  ngOnInit() {
    if (this.initPassword) {
      this.form.controls.currentPassword.clearValidators();
      this.form.updateValueAndValidity();
    }
  }

  async onSubmit() {
    if (this.form.controls.newPassword.value !== this.form.controls.newPasswordConfirm.value) {
      await this.messageBoxService.presentAlert('新しいパスワードと新しいパスワード確認が一致しません。');
      this.form.controls.newPassword.setErrors(Validators.required);
      this.form.controls.newPasswordConfirm.setErrors(Validators.required);
      return;
    }
    let changePasswordDto: ChangePasswordDto = {
      newPassword: this.form.controls.newPassword.value,
      initPassword: this.initPassword,
    };
    if (!this.initPassword) {
      changePasswordDto = {
        ...changePasswordDto,
        currentPassword: this.form.controls.currentPassword.value,
      };
    }
    this.userService.changePassword(this.userId, changePasswordDto).toPromise().then(
      res => {
        this.modalController.dismiss();
        const message = this.initPassword?'パスワードが変更されました。再度ログインしてください。':'パスワードが変更されました。'
        this.messageBoxService.presentAlert(message, 'パスワード変更');
      },
      err => {
        console.log(err);
        if (err.error.text) {
          this.messageBoxService.presentAlert(err.error.text, 'エラー発生');
        } else {
          this.messageBoxService.presentAlert('パスワード変更に失敗しました。', 'エラー発生');
        }
      }
    );
  }

  onCancel() {
    this.modalController.dismiss('cancel');
  }

  // form validator functions
  passwordLengthValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const val: string = control.value;
      if (val.length < 8){
        return {passwordLessThanMaxLength: { value: control.value }};
      }
      return null;
    };
  }
}
