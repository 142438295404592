import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IonModalService {

  checkModalAndClose(): boolean {
    const modal = document.getElementsByTagName('ion-modal');
    const alert = document.getElementsByTagName('ion-alert');
    const popover = document.getElementsByTagName('ion-popover');

    let modalClosed = false;
    let alertClosed = false;
    let popoverClosed = false;

    if (modal.length > 0) {
      [].forEach.call(modal, (el: any) => {
        el.parentNode.removeChild(el);
      });
      modalClosed = true;
    }

    if (alert.length > 0) {
      [].forEach.call(alert, (el: any) => {
        el.parentNode.removeChild(el);
      });
      alertClosed = true;
    }

    if (popover.length > 0) {
      [].forEach.call(popover, (el: any) => {
        el.parentNode.removeChild(el);
      });
      popoverClosed = true;
    }

    return modalClosed || alertClosed || popoverClosed;
  }
}
