import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { WorkDetail } from '../models/work-detail.model';

@Injectable()
export class WorkDetailsEntityService extends EntityCollectionServiceBase<WorkDetail> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('WorkDetail', serviceElementsFactory);
  }
}
