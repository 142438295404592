import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CompanyCreditCardsEntityService } from './company-credit-cards-entity.service';
import { Observable } from 'rxjs'
import { tap, filter, first } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class CompanyCreditCardsResolver implements Resolve<boolean> {
  constructor(private companyCreditCardsEntityService: CompanyCreditCardsEntityService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // if data is not loaded in store, then load new data
    return this.companyCreditCardsEntityService.loaded$.pipe(
      tap(loaded => {
        if (!loaded) {
          this.companyCreditCardsEntityService.getAll();
        }
      }),
      filter(loaded => !!loaded),
      first()
    );
  }
}
