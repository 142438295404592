import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ChangePasswordDto } from "src/app/models/change-password-dto.model";
import { ChangePasswordComponent } from "../components/change-password/change-password.component";

@Injectable({
    providedIn: 'root'
  })
export class UserService {

    apiUrl = '/api/user';

    constructor(
        private http: HttpClient,
        private modalController: ModalController,
    ) { }


  async openChangePasswordModal(userId: number, initPassword = false) {
    const modal = await this.modalController.create({
        component: ChangePasswordComponent,
        componentProps: {
            userId,
            initPassword,
        }
    });

    // modal.onDidDismiss().then(
    //     (message) => {
    //         if (initPassword){
    //             console.log(message);
    //             console.log('new password changed?');
    //         } else {
    //             console.log(message)
    //             console.log('password changed?');
    //         }
    //     }
    // )

    modal.present();
  }

    changePassword(id: number, dto: ChangePasswordDto) {
        return this.http.put(`${this.apiUrl}/change-password/${id}`, dto);
    }
}
