import { EntityMetadataMap, EntityDataModuleConfig } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
  User: {},
  Role: {},
  Subject: {
    entityDispatcherOptions: {
      optimisticDelete: false,
    }
  },
  WorkDetail: {},
  Expense: {},
  ExpensePerUser: {},
  ExpenseStatus: {},
  Report: {},
  ReportPerUser: {},
  ReportStatus: {},
  CompanyCreditCard: {},
  RejectComment: {},
};

const pluralNames = {
  User: 'User',
  Role: 'Role',
  Subject: 'Subject',
  WorkDetail: 'WorkDetail',
  Expense: 'Expense',
  ExpensePerUser : 'ExpensePerUser',
  ExpenseStatus: 'ExpenseStatus',
  Report: 'Report',
  ReportPerUser: 'ReportPerUser',
  ReportStatus: 'ReportStatus',
  CompanyCreditCard: 'CompanyCreditCard',
  RejectComment: 'RejectComment',
};

export const entityConfig: EntityDataModuleConfig = {
  entityMetadata,
  pluralNames
};
