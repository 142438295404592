import { Action, createReducer, on } from '@ngrx/store';
import {
  setCurrentLanguage,
  setErrorMessage,
  setLastDocumentName,
  setLoadingSpinner,
  setUnclaimedReports
} from './shared.actions';


export const sharedFeatureKey = 'shared';

export interface SharedState {
  showLoadingSpinner: boolean;
  errorMessage: string;
  currentLanguage: string;
  unClaimedReports: string[];
  lastDocumentName: string;
}

export const initialState: SharedState = {
  showLoadingSpinner: false,
  errorMessage: '',
  currentLanguage: 'ja',
  unClaimedReports: [],
  lastDocumentName: '',
};

export const sharedReducer = createReducer(
  initialState,
  on(setLoadingSpinner, (state, action) => ({
      ...state,
      showLoadingSpinner: action.status,
    })),
  on(setErrorMessage, (state, action) => ({
      ...state,
      errorMessage: action.message,
    })),
  on(setCurrentLanguage, (state, action) => ({
      ...state,
      currentLanguage: action.CurrentLanguage,
    })),
  on(setUnclaimedReports, (state, action) => ({
    ...state,
    unClaimedReports: action.reportTitleList,
  })),
  on(setLastDocumentName, (state, action) => ({
    ...state,
    lastDocumentName: action.lastDocumentName,
  }))
);

