import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable } from 'rxjs';
import { Expense } from '../models/expense.model';

@Injectable()
export class ExpensesPerUserDataService extends DefaultDataService<Expense> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super('ExpensePerUser', http, httpUrlGenerator);
  }

  getAll(): Observable<Expense[]> {
    return this.http.get<Expense[]>('/api/expenseperuser?order=desc');
  }
}
