import { createReducer, on } from '@ngrx/store';
import { autoLogout, loginSuccess, logout } from './auth.actions';
import { User } from '../../../models/user.model';

export const authFeatureKey = 'auth';

export interface AuthState {
  user: User;
}

export const initialState: AuthState = {
  user: undefined,
};

export const authReducer = createReducer(
  initialState,
  on(loginSuccess, (state, action) => ({ user: action.user })),
  on(logout, autoLogout, (state, action) => ({ user: undefined })),
);
