import { AfterViewInit, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import WebViewer from '@pdftron/webviewer';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-web-viwer',
  templateUrl: './web-viewer.component.html',
  styleUrls: ['./web-viewer.component.scss']
})
export class WebViewerComponent implements OnInit, AfterViewInit {

  @ViewChild('viewer') viewerRef: ElementRef;
  @Input() docPath: string;

  url: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<WebViewerComponent>,
  ) { }

  ngOnInit(): void {
    this.url = this.data.path;
  }

  ngAfterViewInit(): void {
    WebViewer({
      path: '../../assets/lib',
      initialDoc: this.data.path,
    }, this.viewerRef.nativeElement).then(instance => {

    });
  }

  onClose() {
    this.dialogRef.close();
  }

}
