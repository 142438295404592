import { createAction, props } from '@ngrx/store';
import { User } from '../../../models/user.model';


export const loginStart = createAction(
  '[Auth] Login Start',
  props<{ email: string; password: string; }>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{user: User; redirect: boolean}>()
);

export const logout = createAction(
  '[Auth] Logout',
);

export const autoLogin = createAction(
  '[Auth] Auto Login',
);

export const autoLogout = createAction(
  '[Auth] Auto Logout',
);
