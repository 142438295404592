import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Expense } from '../models/expense.model';

@Injectable()
export class ExpensesEntityService extends EntityCollectionServiceBase<Expense> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Expense', serviceElementsFactory);
  }
}
