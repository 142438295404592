import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MessageBoxService {

  constructor(
    private toastController: ToastController,
    private alertController: AlertController,
  ) { }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 3000,
    });
    toast.present();
  }

  async presentAlert(message: string, header: string = 'Alert') {
    const alert = await this.alertController.create({
      header,
      message,
      buttons:[{text:'OK'}],
    });
    alert.present().then(() => {
      // focus on first button
      const firstButton: any = document.querySelector('ion-alert button');
      firstButton.focus();
      return;
    });
  }
}
