import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ExpensesEntityService } from './expenses-entity.service';
import { map } from 'rxjs/operators';
import { SubjectsEntityService } from './subjects-entity.service';
import { WorkDetailsEntityService } from './work-details-entity.service';

@Injectable()
export class WorkDetailsResolver implements Resolve<boolean> {
  constructor(private workDetailsEntityService: WorkDetailsEntityService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.workDetailsEntityService.getAll().pipe(
      map(workDetails => !!workDetails)
    );
  }
}
