import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddNewLinesPipe } from '../pipes/addNewLines';
import {TrustUrlPipe} from './pipes/trustUrl.pipe';
import {WebViewerComponent} from './components/web-viewer/web-viewer.component';

const myDefaultTooltipOptions: TooltipOptions = {
  'show-delay': 100,
  maxWidth: '300px',
};

@NgModule({
    declarations: [
        TruncatePipe,
        AddNewLinesPipe,
        LoadingSpinnerComponent,
        TrustUrlPipe,
        WebViewerComponent,
    ],
  imports: [
    CommonModule,
    TooltipModule.forRoot(myDefaultTooltipOptions as TooltipOptions),
    FlexLayoutModule,
    MatProgressSpinnerModule,
  ],
    exports: [
        TruncatePipe,
        AddNewLinesPipe,
        TooltipModule,
        FlexLayoutModule,
        LoadingSpinnerComponent,
        TrustUrlPipe,
        WebViewerComponent,
    ],
  providers: [
    DatePipe,
  ]
})
export class SharedModule { }
