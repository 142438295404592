import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { authFeatureKey, authReducer, AuthState } from '../../core/auth/store/auth.reducer';
import { sharedFeatureKey, sharedReducer, SharedState } from './shared.reducer';
import { environment } from '../../../environments/environment';

export interface AppState {
  [authFeatureKey]: AuthState;
  [sharedFeatureKey]: SharedState;
}

export const reducers: ActionReducerMap<AppState> = {
  [authFeatureKey]: authReducer,
  [sharedFeatureKey]: sharedReducer,
};


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
